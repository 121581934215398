import React, { useState} from 'react'
import ServicesContainer from './ServicesContainer/ServicesContainer'
import AudioContainer from './AudioContainer/AudioContainer'
import FacilitiesContainer from './FacilitiesContainer/FacilitiesContainer'
import ReceptionContainer from './ReceptionContainer/ReceptionContainer'
import VideosContainer from './VideosContainer/VideosContainer'
import './OlefDetail.scss'
import logoOlef from '../../images/olefweb-white.svg'
import ServicesListing from './ServicesListing/ServicesListing'
import {IFeedDetail} from '../../models/Feeds'
import { Close } from '@mui/icons-material'
import { Grid, DialogContent, Dialog, DialogTitle } from '@mui/material'

const OlefDetail = (props: any) => {
    const [selectedFeed, setSelectedFeed] = useState<IFeedDetail>()
    const [selectedService, setSelectedService] = useState(0)

    React.useEffect(() => {
        if (!props.open) {
            setSelectedFeed(undefined)
            setSelectedService(0)
        } else {
            if (props.services && props.services.length > 0) {
                displayservice(props.services[0])
            }
        }
    }, [props.open])

    function displayservice(service: IFeedDetail) {
        setSelectedFeed(service)
    }

    function setService(index: number) {
        setSelectedService(index)
    }


    return (
        <Dialog open={props.open} onClose={props.handleClose} scroll="body">
            <Close onClick={props.handleClose} style={{position: 'absolute', top: 15, right: 15, cursor: 'pointer'}}/>
            <DialogTitle><img src={logoOlef} alt="icon olef" width={'20px'}/>&nbsp; {props.feed.name}</DialogTitle>
            <DialogContent>
                    <Grid container={true} spacing={1} className="gridContainerDetail">
                        <Grid item={true} xs={12} lg={2} className="menuContainer">
                            <ServicesListing
                                services={props.services}
                                displayservice={displayservice}
                                selectedService={selectedService}
                                setSelectedService={setService}
                            />
                        </Grid>
                        <Grid item={true} xs={12} lg={10}>
                            <Grid container={true} direction="row" className="rowContainer" spacing={2}>
                                <Grid item={true} xs={12}>
                                    <ServicesContainer service={selectedFeed}/>
                                </Grid>
                            </Grid>
                            <Grid container={true} direction="row" className="rowContainer" spacing={2}>
                                <Grid item={true} xs={12} lg={6}>
                                    <FacilitiesContainer service={selectedFeed}/>
                                </Grid>
                                <Grid item={true} xs={12} lg={6}>
                                    <AudioContainer service={selectedFeed}/>
                                </Grid>
                            </Grid>
                            <Grid container={true} direction="row" className="rowContainer" spacing={2}>
                                <Grid item={true} xs={12} lg={6}>
                                    <VideosContainer service={selectedFeed}/>
                                </Grid>
                                <Grid item={true} xs={12} lg={6}>
                                    <ReceptionContainer service={selectedFeed}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default OlefDetail