import React from 'react'
import { IFacility } from '../../../models/Feeds'
import { Card, Table, TableCell, TableHead, TableRow, TableBody, DialogTitle} from '@mui/material'

const FacilitiesContainer = (props: any) => {
    return (
        <Card className="facilitiesContainer">
            <DialogTitle className={'card-title'}>Facilities</DialogTitle>
            <Table>
                <TableHead className='border-head'>
                    <TableRow>
                        <TableCell>Task</TableCell>
                        <TableCell>Facility</TableCell>
                        <TableCell>IP Address</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.service && props.service.facilites && props.service.facilites.map((facility:IFacility, index:number)=>
                    <TableRow key={"Facility_" + index}>
                        <TableCell>{facility.task_no?.task_desc}</TableCell>
                        <TableCell>{facility.trx_resource_desc}</TableCell>
                        <TableCell>{facility.main_ip_address}</TableCell>
                    </TableRow>
                    )}
                </TableBody>
            </Table>
        </Card>
    )
}
export default FacilitiesContainer