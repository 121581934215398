import React from 'react'
import { IAudio } from '../../../models/Feeds'
import { Card, Table, TableCell, TableHead, TableRow, TableBody, DialogTitle} from '@mui/material'

const AudioContainer = (props: any) => {
    return (
        <Card className="audioContainer">
            <DialogTitle className={'card-title'}>Audios</DialogTitle>
            <Table>
                <TableHead className='border-head'>
                    <TableRow>
                        <TableCell>T/C</TableCell>
                        <TableCell>Language</TableCell>
                        <TableCell>Routing</TableCell>
                        <TableCell>Feedback</TableCell>
                        <TableCell>Destination</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {props.service && props.service.audios && props.service.audios.map((audio:IAudio)=>
                    <TableRow key={audio.service_audio_no}>
                        <TableCell>{audio.tc}</TableCell>
                        <TableCell>{audio.track_language}</TableCell>
                        <TableCell>{audio.routing}</TableCell>
                        <TableCell>{audio.feedback}</TableCell>
                        <TableCell>{audio.destination}</TableCell>
                    </TableRow>
                )}
                </TableBody>
            </Table>
        </Card>
    );

}
export default AudioContainer