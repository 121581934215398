import axios from 'axios'
import IUser, { IConnection, IUserOlef } from '../../models/User';

export const userService = {
    login,
    logout
}

async function login(username:any, password:any, session_token: string): Promise<IConnection|null> {
    const data = { 
        username,
        password,
        session_token
    }

    let result:any = null
    if (process && process.env.REACT_APP_URL_LIAZ && process.env.REACT_APP_USER) {
        const url:string = process.env.REACT_APP_URL_LIAZ + process.env.REACT_APP_USER

        let headers = {
            'Content-Type' : 'application/json',
        }

        await axios.post(url, data, { headers: headers })
            .then(res => {
                if (res.data.isConnected) {
                    const user:IUser = {
                        username,
                        password
                    }
                    const userOlef: IUserOlef = {
                        username: res.data.user.Username,
                        providerName: res.data.user.ProviderName,
                        canSeeAll: res.data.user.CanSeeAll
                    }
                    document.cookie = "user=" + JSON.stringify(username)
                    result = {
                        user,
                        connectionCorrectlyDone: true,
                        userOlef
                    }
                } else {
                    logout()
                    if (res.data.status === "error") {
                        result = {
                            connectionCorrectlyDone: false
                        }
                    } else {
                        result = {
                            connectionCorrectlyDone: true
                        }
                    }
                }
            }).catch((err:any) => {
                console.log(err)
                logout()
                result = {
                    connectionCorrectlyDone: false
                }
            })
    }
    return result
}

function logout() {
    document.cookie = "user="
}
