import React from 'react'
import { Card, Table, TableCell, TableHead, TableRow, TableBody, DialogTitle} from '@mui/material'

const ReceptionContainer = (props: any) => {
    return (
        <Card className="receptionContainer">
            <DialogTitle className={'card-title'}>Reception</DialogTitle>
            <Table>
                <TableHead className='border-head'>
                    <TableRow>
                        <TableCell>Frequency</TableCell>
                        <TableCell>Service</TableCell>
                        <TableCell>Modulation</TableCell>
                        <TableCell>Symbol Rate</TableCell>
                        <TableCell>FEC</TableCell>
                        <TableCell>Polarisation</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {props.service &&
                    <TableRow>
                        <TableCell>{props.service.frequency}</TableCell>
                        <TableCell>{props.service.service}</TableCell>
                        <TableCell>{props.service.modulation}</TableCell>
                        <TableCell>{props.service.symbolRate}</TableCell>
                        <TableCell>{props.service.fec}</TableCell>
                        <TableCell>{props.service.polarisation}</TableCell>
                    </TableRow>
                }
                </TableBody>
            </Table>
        </Card>
    )
}
export default ReceptionContainer