import React from 'react'
import './ServicesListing.scss'
import {Card, DialogTitle} from '@mui/material'
import { MenuItem} from '@mui/material'

const ServicesListing = (props: any) => {
   
    function toggleService(index: number, e: any) {
        e.stopPropagation()
        props.setSelectedService(index)
        props.displayservice(props.services[index])

        if (props.toggleDrawer) {
            props.toggleDrawer()
        }
    }

    return (
        <Card className={'ServicesListing'}>
            <DialogTitle className={'card-title'}>Services</DialogTitle>
            {
                props.services && props.services.length === 0 ? <MenuItem>No Services</MenuItem>
                    : <div className="serviceListContainer">
                        {props.services && Array.from(props.services)
                            .map((service: any, index: number) =>
                            <MenuItem className={`${props.selectedService === index ? 'active' : ''}`} key={"Service_" + index}
                                      onClick={(e) => toggleService(index, e)} >
                                {service.description}
                            </MenuItem>
                        )}
                    </div>
            }
        </Card>
    )
}
export default ServicesListing