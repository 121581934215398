import React, {Component} from 'react'
import IUser from '../../models/User'
import {IFeedValue, IFeedDetail} from '../../models/Feeds'
import Menu from '../Menu/Menu'
import OlefGrid from '../OlefGrid/OlefGrid'
import OlefDetail from '../OlefDetail/OlefDetail'
import {olefService} from '../Services/OlefService'
import {IResponse} from '../../Common'
import AlertError from '../AlertError/AlertError'
import '../OlefDetail/OlefDetail.scss'
import { useUserOlefStore } from '../stores/user.store'
import { Grid} from '@mui/material'

const initialState = {
    user: {} as IUser,
    feeds: {} as IFeedValue[],
    services: {} as IFeedDetail[],
    openFeedDetail: false,
    openError: false,
    feedSelected: {} as IFeedValue
}

class Olefs extends Component<any, any> {
    state = initialState

    componentDidMount() {
        const userJson = localStorage.getItem('user')
        const currentUser: IUser = userJson !== null ? JSON.parse(userJson) : {}

        if (currentUser) {
            this.setState({
                user: currentUser
            })
            this.updateGrid(this.loadCurrentDayStart(), this.loadCurrentDayEnd(), "")
        }
    }

    updateGrid(start: Date, end: Date, search: string) {
        olefService.getFeeds(start, end, useUserOlefStore.getState()!.providerName).then((response: IResponse) => {
            if (response.callSuccess) {
                const feeds = response.result.filter((feed: IFeedValue, index: number) => {
                        if (search) {
                            return (
                                (feed.broadcastType && feed.broadcastType.toLowerCase().includes(search.toLowerCase()))
                                || (feed.broadcaster && feed.broadcaster.toLowerCase().includes(search.toLowerCase()))
                                || (feed.name && feed.name.toLowerCase().includes(search.toLowerCase()))
                                || (feed.type && feed.type.toLowerCase().includes(search.toLowerCase()))
                                || (feed.wonosequence && feed.wonosequence.toLowerCase().includes(search.toLowerCase()))
                            )
                        } else {
                            return true
                        }
                    }
                )
                    .sort((a: IFeedValue, b: IFeedValue) => {
                        return (a.name).localeCompare(b.name)
                    })
                    .sort((a: IFeedValue, b: IFeedValue) => {
                        if (a.name === b.name) {
                            return (new Date(a.lineup).getTime() - new Date(b.lineup).getTime())
                        }
                    })

                this.setState({feeds})
            } else {
                this.setState({openError: true})
            }
        })
    }

    openOlefDetail = (feed: IFeedValue) => {
        olefService.getFeedDetails(feed.wonosequence).then((response: IResponse) => {
            if (response.callSuccess) {
                this.setState({services: response.result.filter((service: IFeedDetail) => (service.facilites && service.facilites.length > 0) || (service.audios && service.audios.length > 0) ), openFeedDetail: true, feedSelected: feed})
            } else {
                this.setState({openError: true, openFeedDetail: false, feedSelected: initialState.feedSelected})
            }
        })
    }

    handleCloseError = () => {
        this.setState({openError: false})
    }

    handleCloseFeedDetail = () => {
        this.setState({openFeedDetail: false})
    }

    loadCurrentDayStart = () => {
        let day = new Date()
        day.setHours(0, 0, 0, 0)
        return day
    }

    loadCurrentDayEnd = () => {
        let day = this.loadCurrentDayStart()
        day.setDate(this.loadCurrentDayStart().getDate() + 1)
        return day
    }

    render() {
        return (
            <div style={{flexDirection: 'column', flexWrap: 'initial'}}>
                <Grid container={true} className="olefs">
                    <Menu updateGrid={this.updateGrid.bind(this)} Start={this.loadCurrentDayStart()}
                          End={this.loadCurrentDayEnd()}/>
                    <OlefGrid feeds={this.state.feeds} open={this.openOlefDetail}
                              openFeedDetail={this.state.openFeedDetail}/>
                    <OlefDetail services={this.state.services} open={this.state.openFeedDetail}
                                handleClose={this.handleCloseFeedDetail} feed={this.state.feedSelected}/>
                    <AlertError open={this.state.openError} handleClose={this.handleCloseError}/>
                </Grid>
            </div>
        );
    }
}

export default Olefs
