import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import { Header } from "@shared/eurosport-ui";
import Login from "./Login";

const OlefHeader = (props: any) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      ReactGA.initialize("UA-55127046-11");
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [props.location.pathname]);

  function redirectHome() {
    window.location.href = "/";
  }

  return (
    <AppBar position="sticky" className={process.env.REACT_APP_ENVIRONMENT}>
      {/* <div style={{ backgroundColor: "red", fontWeight: "bold" }}>
        <p style={{ paddingLeft: "16px", color: "white" }}>
          Ongoing Technical Issue, OLEF data are not refreshed since last Night,
          Please contact{" "}
          <a href="mailto:booking@eurosport.com">booking@eurosport.com</a> if
          you need updated feed details for today
        </p>
      </div> */}
      <Header
        appName={"Olef"}
        onLogoClick={redirectHome}
        env="PROD"
        rightComponent={<Login />}
      />
    </AppBar>
  );
};

export default withRouter(OlefHeader);
