import React, { useState, useEffect } from "react"
import { IFeedValue } from "../../models/Feeds"
import './OlefGrid.scss'
import moment from 'moment'
import {Grid, Card, Table, TableHead, TableCell, TableRow, TableBody, Tooltip} from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"

const OlefGrid = (props: any) => {
    const [feeds, setFeeds] = useState(props.feeds)
    const [selectedRow, setSelectedRow] = useState(-1)
    const [orderBy, setOrderBy] = React.useState(['', 'asc']);

    const showDetail = (feed: IFeedValue, index: number) => {
        setSelectedRow(index)

        props.open(feed)
    }

    // const loadSignalTypeLabel = (type: string) => {
    //     let label = ''
    //     switch (type) {
    //         case 'O':
    //             label = 'Internal/test';
    //             break;
    //         case 'P':
    //             label = 'Unilateral';
    //             break;
    //         case 'M':
    //             label = 'Multilateral';
    //             break;
    //         default :
    //             label = ''
    //     }

    //     return label
    // }

    useEffect(() => {
        setFeeds(props.feeds)
        if (!props.openFeedDetail) {
            setSelectedRow(-1)
        }
    }, [props.feeds, props.openFeedDetail])

    const sortBy = (columnName: string) => {
        if (feeds.length > 0) {
            if (orderBy[0] === columnName) {
                if (orderBy[1] === 'asc') {
                    sortDesc(columnName)
                } else {
                    sortAsc(columnName)
                }
            } else {
                sortAsc(columnName)
            }
        }
    }

    const sortAsc = (columnName: string) => {
        let feedsOrdered = []
        if (columnName === 'name' || columnName === 'description' || columnName === 'broadcaster' || columnName === 'broadcastType') {
            feedsOrdered = feeds.sort((a: IFeedValue, b: IFeedValue) => {
                if (!a[columnName]) {
                    return 1
                }
                if (!b[columnName]) {
                    return -1
                }
                return (a[columnName]).localeCompare(b[columnName])
            })
        } else {
            feedsOrdered = feeds.sort((a: IFeedValue, b: IFeedValue) => {
                if (!a.lineup) {
                    return 1
                }
                if (!b.lineup) {
                    return -1
                }
                return (new Date(a.lineup).getTime() - new Date(b.lineup).getTime())
            })
        }
        setOrderBy([columnName, 'asc'])
        setFeeds(feedsOrdered)
    }

    const sortDesc = (columnName: string) => {
        let feedsOrdered = []
        if (columnName === 'name' || columnName === 'description' || columnName === 'broadcaster' || columnName === 'broadcastType') {
            feedsOrdered = feeds.sort((a: IFeedValue, b: IFeedValue) => {
                if (!a[columnName]) {
                    return -1
                }
                if (!b[columnName]) {
                    return 1
                }
                return (b[columnName]).localeCompare(a[columnName])
            })
        } else {
            feedsOrdered = feeds.sort((a: IFeedValue, b: IFeedValue) => {
                if (!a.lineup) {
                    return -1
                }
                if (!b.lineup) {
                    return 1
                }
                return (new Date(b.lineup).getTime() - new Date(a.lineup).getTime())
            })
        }
        setOrderBy([columnName, 'desc'])
        setFeeds(feedsOrdered)
    }

    return (
        <Card className="grid">
            <Grid item={true} xs={12} md={12}>
                <Card>
                    <div className="overflow-x">
                        <Table className="-striped -highlight" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell key={'7'} onClick={() => sortBy('wonosequence')} style={{cursor: 'pointer'}}>TO Number</TableCell>
                                    <TableCell key="0" onClick={() => sortBy('name')} style={{cursor: 'pointer'}}>Feed name
                                        {
                                            orderBy[0] === 'name' &&
                                            (orderBy[1] === 'asc'
                                                ? <ArrowDropUp style={{verticalAlign: 'bottom'}}/>
                                                : <ArrowDropDown style={{verticalAlign: 'bottom'}}/>)
                                        }
                                    </TableCell>
                                    <TableCell key="1" onClick={() => sortBy('description')} style={{cursor: 'pointer'}}>Description {
                                        orderBy[0] === 'description' &&
                                        (orderBy[1] === 'asc'
                                            ? <ArrowDropUp style={{verticalAlign: 'bottom'}}/>
                                            : <ArrowDropDown style={{verticalAlign: 'bottom'}}/>)
                                    }</TableCell>
                                    <TableCell key="2" onClick={() => sortBy('lineup')} style={{cursor: 'pointer'}}>Line Up (CET) {
                                        orderBy[0] === 'lineup' &&
                                        (orderBy[1] === 'asc'
                                            ? <ArrowDropUp style={{verticalAlign: 'bottom'}}/>
                                            : <ArrowDropDown style={{verticalAlign: 'bottom'}}/>)
                                    }</TableCell>
                                    <TableCell key="3">Start time (CET)</TableCell>
                                    <TableCell key="4">End time (CET)</TableCell>
                                    <TableCell key="5" onClick={() => sortBy('broadcaster')} style={{cursor: 'pointer'}}>Broadcaster {
                                        orderBy[0] === 'broadcaster' &&
                                        (orderBy[1] === 'asc'
                                            ? <ArrowDropUp style={{verticalAlign: 'bottom'}}/>
                                            : <ArrowDropDown style={{verticalAlign: 'bottom'}}/>)
                                    }</TableCell>
                                    {/* <TableCell key="6">Signal</TableCell> */}
                                    <TableCell key="6" onClick={() => sortBy('broadcastType')} style={{cursor: 'pointer'}}>Broadcast Type {
                                        orderBy[0] === 'broadcastType' &&
                                        (orderBy[1] === 'asc'
                                            ? <ArrowDropUp style={{verticalAlign: 'bottom'}}/>
                                            : <ArrowDropDown style={{verticalAlign: 'bottom'}}/>)
                                    }</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!feeds.length ? feeds.map((feed: IFeedValue, rowIndex: number) =>
                                        <TableRow key={`${feed.wonosequence}-${rowIndex}`}
                                                  className="table-striped cursor-pointer"
                                                  selected={selectedRow === rowIndex}
                                                  onClick={() => showDetail(feed, rowIndex)}
                                        >
                                            <TableCell>{feed.wonosequence}</TableCell>
                                            <TableCell style={{
                                                maxWidth: '300px',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden'
                                            }} key={rowIndex + "_0"}>
                                                <span title={feed.name}><b className={'feedName'}>{feed.name}</b></span>
                                            </TableCell>
                                            <TableCell style={{
                                                maxWidth: '300px',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden'
                                            }}>
                                                <Tooltip title={feed.description || ''}>
                                                    <span>{feed.description}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell
                                                key={rowIndex + "_1"}>{moment(feed.lineup).format('DD/MM/YYYY HH:mm')}</TableCell>
                                            <TableCell
                                                key={rowIndex + "_2"}>{feed.startDate ? moment(feed.startDate).format('HH:mm') : 'No start date'}</TableCell>
                                            <TableCell
                                                key={rowIndex + "_3"}>{moment(feed.endDate).format('HH:mm')}</TableCell>
                                            <TableCell style={{
                                                maxWidth: '250px',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden'
                                            }} key={rowIndex + "_4"}>
                                                <span title={feed.broadcaster}>{feed.broadcaster}</span>
                                            </TableCell>
                                            {/* <TableCell key={rowIndex + "_5"}>
                                                TYPE = {loadSignalTypeLabel(feed.type)} <br/>
                                                ORIG = {feed.origin} <br/>
                                                VIA = {feed.via}
                                            </TableCell> */}
                                            <TableCell key={rowIndex + "_5"}>{feed.broadcastType}</TableCell>
                                        </TableRow>
                                    ) :
                                    <TableRow><TableCell colSpan={99}>No Feeds</TableCell></TableRow>}
                            </TableBody>
                        </Table>
                    </div>
                </Card>
            </Grid>
        </Card>
    )
}
export default OlefGrid