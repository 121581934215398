import React, { useState } from 'react'
import './Menu.scss'
import { DateRangePicker } from '@shared/eurosport-ui'
import dayjs from 'dayjs'
import { TextField } from '@mui/material'
import {Card, Grid} from '@mui/material'

const Menu = (props: any) => {
    const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs())
    const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs())
    const [Search] = useState(props.Search)

    let timer:any = null;

    function updateFilterDate(start: string | null, end: string | null):void {
        const newStartDate:dayjs.Dayjs = dayjs(start)
        const newEndDate:dayjs.Dayjs = dayjs(end).add(1, 'day')
        setStartDate(newStartDate)
        setEndDate(newEndDate)

        updateFilters(newStartDate, newEndDate, Search)
    }

    function updateSearchField(e:any) {
        const value = e.target.value
        if (timer) {
            clearTimeout(timer)
            timer = null
        }
        timer = setTimeout(function() {
            const newStartDate:dayjs.Dayjs = dayjs(startDate.format('YYYY-MM-DD'))
            const newEndDate:dayjs.Dayjs = dayjs(endDate.format('YYYY-MM-DD')).add(1, 'day')
            setStartDate(newStartDate)
            setEndDate(newEndDate)

            updateFilters(newStartDate, newEndDate, value)
        }, 500)
    }

    function updateFilters(start:dayjs.Dayjs, end:dayjs.Dayjs, search:string) {
        
        props.updateGrid(start, end, search)
    }

    return (
        <div className="filterContainer">
            <Grid item={true} xs={12}>
                <Card>
                    <Grid container className="containerFilter">
                        <Grid item xs={12} sm={12} md={3} lg={2}>
                            <DateRangePicker 
                                start={startDate.format()}
                                end={endDate.format()}
                                handleChange={updateFilterDate}
                                />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField onChange={(evt:any) => updateSearchField(evt)} defaultValue={Search} placeholder="Search" style={{ width: '200px', height: '40px!important' }}/>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </div>
    )
}
export default Menu