import React, { Component } from 'react'
import { userService } from '../Services/UserService'
import './login.scss'
import { useUserOlefStore } from '../stores/user.store'
import { Button, TextField, Grid, Card, Paper } from '@mui/material'

const initialState = {
    username: '',
    password: '',
    submitted: false,
    loading: false,
    invalidLogin: false,
    connectionProblem: false,
    error: ''
}

class Login extends Component<any, any> {
    state = initialState
    sessionToken: any = ''

    componentDidMount() {
        userService.logout()
    }

    handleChange(e: any) {
        this.setState({[e.target.name]: e.target.value})
    }

    handleSubmit = (e: any) => {
        e.preventDefault()

        // stop here if form is invalid
        if (!(this.state.username && this.state.password)) {
            return
        }

        this.setState({loading: true, submitted: true})
        this.callBackSubmit()
    }

    callBackSubmit = () => {
        userService.login(this.state.username, this.state.password, this.sessionToken.value).then((data: any) => {
            if (data.connectionCorrectlyDone && data.user) {
                useUserOlefStore.setState(data.userOlef);
                localStorage.setItem('userOlef', JSON.stringify(data.userOlef))
                sessionStorage.setItem("isAuthenticated", "true")

                const {from} = this.props.location.state || {from: {pathname: "/"}};
                this.props.history.push(from);
            } else if (data.connectionCorrectlyDone && !data.user) {
                this.setState({invalidLogin: true, loading: false})
            } else {
                this.setState({connectionProblem: true, loading: false})
            }
        })
    }

    render() {
        return (
            <form onSubmit={ this.handleSubmit } id="form_login">
                <input type="hidden" name="sessionToken" id="sessionToken"
                       ref={ sessionToken => (this.sessionToken = sessionToken) }
                       onInput={ this.callBackSubmit }/>
                <div className="loginForm">
                    <Paper className="loginCard">
                        <Card>
                            <Grid container className="loginHeader">
                                Login
                            </Grid>
                            <Grid container className="gridContainer">
                                { this.state.connectionProblem &&
								<div className="error">
									<h3>Cannot connect to Olef</h3>
									<hr/>
								</div>
                                }
                                { this.state.invalidLogin &&
								<div className="error">
									<h3>Invalid username or password</h3>
									<hr/>
								</div>
                                }
                                <Grid container 
                                      className={ 'form-group' + (this.state.submitted && !this.state.username ? ' has-error' : '') }>
                                    <TextField
                                        id="username"
                                        label="Username"
                                        name="username"
                                        type="label"
                                        fullWidth
                                        autoFocus
                                        onChange={ this.handleChange.bind(this) }
                                        error={ this.state.submitted && !this.state.username }
                                        helperText={ this.state.submitted && !this.state.username ? 'Username is required' : '' }
                                    />
                                </Grid>
                                <Grid container 
                                      className={ 'form-group' + (this.state.submitted && !this.state.username ? ' has-error' : '') }>
                                    <TextField
                                        id="password" label="Password" name="password" type="password" fullWidth
                                        onChange={ this.handleChange.bind(this) }
                                        error={ this.state.submitted && !this.state.password }
                                        helperText={ this.state.submitted && !this.state.password ? 'Password is required' : '' }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="buttonContainer">
                                <Button type={ 'submit' } variant={ 'contained' } style={ {textTransform: "none"} }
                                        id="LoginButton" disabled={ false }>Login</Button>
                            </Grid>
                        </Card>
                    </Paper>
                </div>
            </form>
        );
    }
}

export default Login
