import React from 'react'
import { Card, Table, TableCell, TableHead, TableRow, TableBody, DialogTitle} from '@mui/material'

const VideosContainer = (props: any) => {
    return (
        <Card className="videosContainer">
            <DialogTitle className={'card-title'}>Video settings</DialogTitle>
            <Table>
                <TableHead className='border-head'>
                    <TableRow>
                        <TableCell>Aspect Ratio</TableCell>
                        <TableCell>Video format</TableCell>
                        <TableCell>Framerate</TableCell>
                        <TableCell>Video Encoding</TableCell>
                        <TableCell>Video Encryption</TableCell>
                        <TableCell>Encryption Key</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.service &&
                    <TableRow>
                        <TableCell>{props.service.aspectRation}</TableCell>
                        <TableCell>{props.service.videoFormat}</TableCell>
                        <TableCell>{props.service.framerate}</TableCell>
                        <TableCell>{props.service.videoEncoding}</TableCell>
                        <TableCell>{props.service.videoEncryption}</TableCell>
                        <TableCell>{props.service.encryptionKey}</TableCell>
                    </TableRow>
                    }
                </TableBody>
            </Table>
        </Card>
    )
}
export default VideosContainer