import React from 'react'
import { Card, Table, TableCell, TableHead, TableRow, TableBody, DialogTitle} from '@mui/material'

const ServicesContainer = (props: any) => {
    return (
        <Card className="serviceContainer">
            <DialogTitle className={'card-title'}>Service</DialogTitle>
            <Table>
                <TableHead className='border-head'>
                    <TableRow>
                        <TableCell>Service</TableCell>
                        <TableCell>Service Profile</TableCell>
                        <TableCell>IP encoder/decoder</TableCell>
                        <TableCell>IP provider</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.service &&
                    <TableRow>
                        <TableCell>{props.service.service}</TableCell>
                        <TableCell>{props.service.profile}</TableCell>
                        <TableCell>{props.service.ipEncoderDecoder}</TableCell>
                        <TableCell>{props.service.ipProvider}</TableCell>
                    </TableRow>
                    }
                </TableBody>
            </Table>
        </Card>
    )
}
export default ServicesContainer